<template>
  <div>
    <heads></heads>
    <div
      class="cen"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="cen_box">
        <router-link to="/chuan/cxKcb" class="cx">
          <img src="../../assets/images/cx.png" alt="" />
        </router-link>

        <div v-for="(item, index) in courses" :key="index">
          <div class="cen-header" v-if="item.listorder == 1">
            <router-link
              :to="{
                path: '/chuan/guoxue',
                query: { id: items.id },
              }"
              class="iitem"
              v-for="(items, indexs) in item.practice_course"
              :key="indexs"
            >
              {{ items.name }}
            </router-link>
          </div>
          <div class="cen-top" v-if="item.listorder == 2">
            <router-link
              :to="{
                path: '/chuan/nationCulture',
                query: { id: items.id },
              }"
              class="iitem"
              v-for="(items, indexs) in item.practice_course"
              :key="indexs"
            >
              {{ items.name }}
            </router-link>
          </div>
          <div class="cen-com" v-if="item.listorder == 3">
            <router-link
              :to="{
                path: '/chuan/history',
                query: { id: items.id },
              }"
              class="iitem"
              v-for="(items, indexs) in item.practice_course"
              :key="indexs"
            >
              {{ items.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getPracticeCourses } from "@api/public";
export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      courses: [],
      backgroud: "",
    };
  },
  mounted: function () {
    let that = this;
    getPracticeCourses().then(function (res) {
      that.$set(that, "courses", res.data);
    });
  },
  created: function () {
    document.title = this.$route.meta.title;
    this.backgroud = this.$route.meta.background;
  },
};
</script>
<style scoped>
.img {
  width: 76px;
  height: 142px;
  position: absolute;
  left: 296px;
  top: 1px;
}
.cx {
  width: 108px;
  height: 71px;
  display: block;
  position: relative;
  top: 211px;
  left: 142px;
}
.cx > img {
  width: 100%;
  height: 100%;
}
.cen {
  width: 100%;
  min-width: 1484px;
  height: 1874px;

  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.cen_box {
  width: 1484px;
  height: 1874px;
  margin: 0 auto;
  position: relative;
}
.cen-header {
  width: 600px;
  height: 100px;
  position: absolute;
  top: 400px;
  left: 520px;
  display: flex;
  justify-content: space-between;
}
.iitem {
  display: inline-block;
  width: 15px;
  color: #000;
  font-size: 23px;
  text-decoration: none;
  font-family: "华文行楷";
  writing-mode: vertical-lr;
}
.iitem:hover {
  color: #e2cea0;
}
.cen-top {
  width: 706px;
  height: 350px;
  position: absolute;
  top: 675px;
  left: 230px;
  display: flex;
  line-height: 1.15;
  justify-content: space-between;
}
.cen-com {
  width: 650px;
  height: 200px;
  position: absolute;
  top: 1055px;
  left: 570px;
  display: flex;
  justify-content: space-between;
}
.cen-but {
  width: 650px;
  height: 150px;
  position: absolute;
  top: 1467px;
  left: 350px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
</style>
