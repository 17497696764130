var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heads'),_c('div',{staticClass:"cen",style:({
      backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
    })},[_c('div',{staticClass:"cen_box"},[_c('router-link',{staticClass:"cx",attrs:{"to":"/chuan/cxKcb"}},[_c('img',{attrs:{"src":require("../../assets/images/cx.png"),"alt":""}})]),_vm._l((_vm.courses),function(item,index){return _c('div',{key:index},[(item.listorder == 1)?_c('div',{staticClass:"cen-header"},_vm._l((item.practice_course),function(items,indexs){return _c('router-link',{key:indexs,staticClass:"iitem",attrs:{"to":{
              path: '/chuan/guoxue',
              query: { id: items.id },
            }}},[_vm._v(" "+_vm._s(items.name)+" ")])}),1):_vm._e(),(item.listorder == 2)?_c('div',{staticClass:"cen-top"},_vm._l((item.practice_course),function(items,indexs){return _c('router-link',{key:indexs,staticClass:"iitem",attrs:{"to":{
              path: '/chuan/nationCulture',
              query: { id: items.id },
            }}},[_vm._v(" "+_vm._s(items.name)+" ")])}),1):_vm._e(),(item.listorder == 3)?_c('div',{staticClass:"cen-com"},_vm._l((item.practice_course),function(items,indexs){return _c('router-link',{key:indexs,staticClass:"iitem",attrs:{"to":{
              path: '/chuan/history',
              query: { id: items.id },
            }}},[_vm._v(" "+_vm._s(items.name)+" ")])}),1):_vm._e()])})],2)]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }